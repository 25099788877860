import React from 'react'
import PartnersComponent from '../components/static/Partners'
import Newsletter from '../components/static/Newsletter'

import bg1 from '../assets/img/nelsoten-bg.jpg'
import bg2 from '../assets/img/min/nelsoten-bg-min.jpg'
import { useBackgroundImageLoader } from '../hooks/useImageLoader'; 

const Partners = () => {
    const { backgroundImage, loading, loaded } = useBackgroundImageLoader(bg2, bg1);

  return <div className='homepage-container'>
    <div
        className={`homepage-landing smaller-landing ${loading ? 'loading' : 'loaded'} ${loaded ? 'image-loaded' : ''}`}
        style={{ backgroundImage }}
      >
        <div className='homepage-landing-content'>
            <h1 >Partnerství s lídry v oboru</h1>
            <h3>Věříme v sílu strategických aliancí. Díky naší spolupráci s předními společnostmi, jako jsou Binance, Bitfinex, Coinbase, Poloniex, Alverdo a Kraken, dokážeme našim klientům poskytovat maximální podporu a nabízet individuální řešení šitá na míru jejich potřebám.</h3>
        </div>
    </div>

    <section>
        <PartnersComponent />
    </section>

    <section>
        <Newsletter />
    </section>
</div>
}

export default Partners
import React from 'react';
import './CustomNotification.css'; // Adjust the path to your CSS file

const CustomNotification = ({ message, type, show }) => {
    return (
        <div className={`notification ${type} ${show ? 'show' : ''}`}>
            {message}
        </div>
    );
};

export default CustomNotification;
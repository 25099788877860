import React from 'react';
import Navbar from "./static/Navbar";
import FooterComponent from './static/Footer';
import { Outlet } from "react-router-dom";
import './SharedLayout.css';

const SharedLayout = () => {
  return (
    <div className="container">
      <Navbar />
      <div className="main-content">
        <Outlet />
      </div>
      <FooterComponent />
    </div>
  );
};

export default SharedLayout;
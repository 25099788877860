import React, { useState } from 'react';
import './ContactUs.css';
import bg1 from '../../assets/img/nelsoten-contactus.jpg';
import bg2 from '../../assets/img/min/nelsoten-contactus-min.jpg';
import { useBackgroundImageLoader } from '../../hooks/useImageLoader';
import Modal from '../dynamic/ModalForm'; // Importuj modální komponentu
import useFormHandler from '../../hooks/useFormHandler';

const ContactUs = () => {
  const { backgroundImage, loading, loaded } = useBackgroundImageLoader(bg2, bg1);
  
  const [modalOpen, setModalOpen] = useState(false);
  
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    agreement: false,
  };

  const { formState, handleChange, handleSubmit, notification, resetForm } = useFormHandler('schuzka', initialState);

  const openModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
    resetForm();
  };

  return (
    <>
    <div
        className={`contact-us-container ${loading ? 'loading' : 'loaded'} ${loaded ? 'image-loaded' : ''}`}
        style={{ backgroundImage }}
      >
      <h2>Představujeme Vám nový pohled na digitální trhy</h2>
      <p>Kontaktujte nás ještě dnes a proberte své finanční cíle a investiční možnosti s našimi odborníky.</p>
      <div className="contact-us-container_links">
        <a href="#" onClick={openModal}>Sjednat schůzku</a>
        <a href="">Chci vědět více</a>
      </div>
    </div>
    <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        onSubmit={(e) => handleSubmit(e, closeModal)}
        formState={formState}
        handleChange={handleChange}
        notification={notification}
      />
    </>
  );
};

export default ContactUs;
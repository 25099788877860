import React from 'react';
import './Navbar.css'; // Import CSS pro stylizaci
import logo from '../../assets/img/Nelsoten.svg';
import { Link, NavLink } from 'react-router-dom';
import useFormHandler from '../../hooks/useFormHandler';
import CustomNotification from '../dynamic/CustomNotification';

const Navbar = () => {
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    agreement: false,
  };

  const { formState, handleChange, handleSubmit, notification, resetForm } = useFormHandler('schuzka', initialState);

  const [scrolling, setScrolling] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(() => {
    if (modalOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [modalOpen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const openModal = () => {
    setModalOpen(true);
    setMenuOpen(false);
  };

  const closeModal = () => {
    setModalOpen(false);
    resetForm(); // Resetuje formulář
  };

  return (
    <div className={`navbar ${scrolling ? 'scrolling' : ''}`}>
      <div className="navbar-inner">
        <div className="navbar-logo">
          <Link to={'/'}>
            <img src={logo} alt="Nelsoten" />
          </Link>
        </div>
        <div className="navbar-links">
          <NavLink to="/o-nas">O nás</NavLink>
          <NavLink to="/poradenstvi">Poradenství</NavLink>
          <NavLink to="/edukace">Edukace</NavLink>
          <NavLink to="/nasi-partneri">Naši Partneři</NavLink>
          <NavLink to="/kariera">Kariéra</NavLink>
          <NavLink to="/kontakt">Kontakt</NavLink>
          <div className="navbar-cta">
            <button onClick={openModal}>Sjednat schůzku</button>
          </div>
        </div>
        <div className={`burger-menu ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
      <div className={`menu-overlay ${menuOpen ? 'open' : ''}`}>
        <div className="menu-logo">
          <img src={logo} alt="Nelsoten" />
        </div>
        <div className="menu-content">
          <NavLink to="/o-nas" onClick={toggleMenu}>O nás</NavLink>
          <NavLink to="/poradenstvi" onClick={toggleMenu}>Poradenství</NavLink>
          <NavLink to="/edukace" onClick={toggleMenu}>Edukace</NavLink>
          <NavLink to="/nasi-partneri" onClick={toggleMenu}>Naši Partneři</NavLink>
          <NavLink to="/kariera" onClick={toggleMenu}>Kariéra</NavLink>
          <NavLink to="/kontakt" style={{marginBottom: "10px"}} onClick={toggleMenu}>Kontakt</NavLink>
          <div className="mobile-cta">
            <NavLink onClick={openModal}>Sjednat schůzku</NavLink>
          </div>
        </div>
      </div>

      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <button className="close-modal" onClick={closeModal}>
              &times;
            </button>
            <h2>Sjednat schůzku</h2>
            <form className="modal-form" onSubmit={(e) => handleSubmit(e, closeModal)}>
              <div className="form-group">
                <label>Jméno</label>
                <input
                  type="text"
                  name="firstName"
                  value={formState.firstName || ''}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Příjmení</label>
                <input
                  type="text"
                  name="lastName"
                  value={formState.lastName || ''}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={formState.email || ''}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Telefon</label>
                <input
                  type="tel"
                  name="phone"
                  placeholder="420 123 456 789"
                  value={formState.phone || ''}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group-checkbox">
                <input
                  type="checkbox"
                  name="agreement"
                  id="agreement"
                  checked={formState.agreement || false}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="agreement" style={{ marginLeft: '5px', cursor: 'pointer' }}>
                  Souhlasím se zpracováním osobních údajů a obchodními podmínkami
                </label>
              </div>
              <button type="submit">Odeslat</button>
            </form>
          </div>
        </div>
      )}

      {notification && notification.show && (
        <CustomNotification message={notification.message} type={notification.type} show={notification.show} />
      )}
    </div>
  );
};

export default Navbar;
import React from 'react'
import ImageTextSection from '../components/dynamic/ImageTextSection';
import { faUserTie, faChartLine, faMagnifyingGlassDollar, faLightbulb, faBriefcase, faBalanceScale, faCogs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Newsletter from '../components/static/Newsletter'
import img1 from '../assets/img/nelsoten-consultancy1.jpg'
import piimg1 from '../assets/img/min/nelsoten-consultancy1-min.jpg'
import img2 from '../assets/img/nelsoten-consultancy2.jpg'
import piimg2 from '../assets/img/min/nelsoten-consultancy2-min.jpg'
import img3 from '../assets/img/nelsoten-bg3.jpg'
import piimg3 from '../assets/img/min/nelsoten-bg3-min.jpg'
import bg1 from '../assets/img/nelsoten-bg2.jpg'
import bg2 from '../assets/img/min/nelsoten-bg2-min.jpg'
import { useBackgroundImageLoader } from '../hooks/useImageLoader'; 

const Consultancy = () => {
    const sectionOne = {
        placeholderImage: piimg1,
        image: img1,
        title: 'Objevte příležitosti a zhodnoťte rizika na trzích s tokenizovanými aktivy',
        subtitle: 'Tržní analýza',
        desc: 'Náš tým odborníků se zaměřuje na analýzu trhů, navrhování investičních strategií a identifikaci příležitostí pro strategické plánování investic prostřednictvím hloubkové analýzy trhu.',
        features: [
          {
            icon: <FontAwesomeIcon icon={faMagnifyingGlassDollar} size='2x'/>,
            title: 'Hloubková analýza trhu',
            description: 'Detailní analýza finančních trhů, zaměřená na identifikaci klíčových příležitostí a rizik.',
          },
          {
            icon: <FontAwesomeIcon icon={faChartLine} size='2x'/>,
            title: 'Strategické plánování investic',
            description: 'Navrhování a implementace strategií pro dosažení optimálního výnosu na základě tržních podmínek.',
          },
          {
            icon: <FontAwesomeIcon icon={faLightbulb} size='2x'/>,
            title: 'Identifikace a využití příležitostí',
            description: 'Hledání a využívání nových investičních příležitostí na dynamických trzích.',
          },
        ],
        buttonTextLeft: 'Chci vědět více',
        buttonTextRight: 'Sjednat schůzku',
        buttonHref: '/o-nas',
        imageLeft: true,
      };

      const sectionTwo = {
        placeholderImage: piimg2,
        image: img2,
        title: 'Poradenské služby pro tokenizovaná aktiva a krypto trhy',
        subtitle: 'Strategie',
        desc: 'Poskytujeme odborné poradenské služby v oblasti správy portfolia a hodnocení rizik na trzích tokenizovaných aktiv. Náš tým odborníků rychle analyzuje Vaše současné podmínky a navrhne strategii, která Vám pomůže lépe se orientovat v dynamickém a progresivním světě kryptoaktiv.',
        features: [
          {
            icon: <FontAwesomeIcon icon={faBriefcase} size='2x'/>,
            title: 'Správa portfolia',
            description: 'Nabízíme individuální řešení správy portfolia přizpůsobená Vašim investičním cílům a toleranci k riziku.',
          },
          {
            icon: <FontAwesomeIcon icon={faBalanceScale} size='2x'/>,
            title: 'Posouzení příležitostí a rizik',
            description: 'Náš zkušený tým provádí důkladné posouzení příležitostí a rizik, aby Vám pomohl činit informovaná investiční rozhodnutí.',
          },
        ],
        buttonTextLeft: 'Zjistit více',
        buttonTextRight: 'Sjednat schůzku',
        buttonHref: '/kontakt',
        imageLeft: false,
      };

      const sectionThree = {
        placeholderImage: piimg3,
        image: img3,
        title: 'Využití tržních příležitostí prostřednictvím strategického poradenství',
        subtitle: 'Exekuce',
        desc: 'Používáme přesný a daty podložený přístup k identifikaci slibných investičních příležitostí na dynamických a progresivních trzích. Prostřednictvím hloubkové analýzy a s využitím naší rozsáhlé sítě partnerů, včetně společností Binance, Bitfinex, Coinbase, Poloniex, Alverdo a Kraken, poskytujeme našim klientům individuální podporu a maximalizaci výnosů.',
        features: [
          {
            icon: <FontAwesomeIcon icon={faUserTie} size='2x'/>,
            title: 'Odbornost',
            description: 'Náš tým odborníků realizuje a neustále kontroluje nastavenou strategii v souladu s tržními trendy a riziky.',
          },
          {
            icon: <FontAwesomeIcon icon={faCogs} size='2x'/>,
            title: 'Řešení',
            description: 'Prostřednictvím našich partnerů nabízíme obchodní řešení na míru, která podporují úspěšnou exekuci investičních strategií.',
          },
        ],
        buttonTextLeft: 'Zjistit více',
        buttonTextRight: 'Sjednat schůzku',
        buttonHref: '/o-nas',
        imageLeft: true,
      };

      const { backgroundImage, loading, loaded } = useBackgroundImageLoader(bg2, bg1);

  return <div className='homepage-container consultancy'>
      <div
        className={`homepage-landing smaller-landing ${loading ? 'loading' : 'loaded'} ${loaded ? 'image-loaded' : ''}`}
        style={{ backgroundImage }}
      >
      <div className='homepage-landing-content'>
          <h1 >Individuální finanční&nbsp;poradenství</h1>
          <h3>Naše poradenské služby jsou navrženy tak, aby Vám poskytly přesné a praktické rady, které zohledňují Vaše specifické potřeby a cíle. Pomůžeme Vám se zorientovat na digitálních trzích a optimalizovat Vaše investiční strategie pro maximální úspěch.</h3>
      </div>
  </div>
  <section>
      <ImageTextSection {...sectionOne} />
  </section>
  <section>
    <ImageTextSection {...sectionTwo} />
  </section>

  <section>
    <ImageTextSection {...sectionThree} />
  </section>

  <section>
      <Newsletter />
    </section>
</div>
}

export default Consultancy
import React from 'react';
import ContentSection from '../components/dynamic/ContentSection';
import Newsletter from '../components/static/Newsletter';
import Partners from '../components/static/PartnersComponent';
import './Carrier.css';
import bg1 from '../assets/img/nelsoten-bg4.jpg';
import bg2 from '../assets/img/min/nelsoten-bg4-min.jpg';
import { useBackgroundImageLoader } from '../hooks/useImageLoader'; 
import useFormHandler from '../hooks/useFormHandler';
import CustomNotification from '../components/dynamic/CustomNotification'; 

const Carrier = () => {
  const { formState, handleChange, handleSubmit, notification } = useFormHandler('kariera');
  const { backgroundImage, loading, loaded } = useBackgroundImageLoader(bg2, bg1);

  const isConsentChecked = formState.consent;

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, ''); // Odstraní vše kromě čísel
    const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,3})(\d{0,3})$/);

    if (match) {
      return [match[1], match[2], match[3], match[4]]
        .filter(Boolean)
        .join(' ')
        .trim();
    }
    return value;
  };

  const handlePhoneChange = (e) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    handleChange({
      target: {
        name: e.target.name,
        value: formattedValue,
      },
    });
  };

  return (
    <div className='homepage-container carrier'>
      <div
        className={`homepage-landing smaller-landing carrier ${loading ? 'loading' : 'loaded'} ${loaded ? 'image-loaded' : ''}`}
        style={{ backgroundImage }}
      >
        <div className='homepage-landing-content'>
          <h1>Připojte se k Nelsoten</h1>
          <h3>Staňte se součástí týmu, který formuje budoucnost digitálních trhů. Hledáme talentované a motivované profesionály, kteří chtějí růst a inovovat v oblasti digitálních aktiv a kryptoměn.</h3>
        </div>
      </div>

      <section>
        <ContentSection
          heading5="Nové příležitosti"
          heading2="Připojte se k nám"
          paragraphs={[
            "Připojte se ke společnosti Nelsoten a rozvíjejte svou kariéru! Náš tým nabízí dynamickou a přátelskou kulturu se zaměřením na výsledky.",
            "Co nabízíme:",
            "— Možnost získat cenné zkušenosti a vzdělání v oblasti dynamických trhů a technologií",
            "— Rychlý růst a příležitost k osobnímu rozvoji",
            "— Praxe s významnými firmami a technologiemi v odvětví",
            "— Mentorství a individuální plán pro Váš kariérní rozvoj",
            "— Přátelské a dynamické pracovní prostředí",
            "— Nejnovější technologie a školicí systémy",
            "— Obchodní a manažerská školení od leaderů v oboru",
            "— Vlastní hardware (mobilní telefon, notebook)",
            "— Multisport karta",
            "— Pravidelné teambuildingové aktivity",
            "Prozkoumejte naše aktuální volná pracovní místa a připojte se k našemu dynamickému týmu."
          ]}
          className="content-section"
        />
      </section>

      <section className="carrier-content-section">
        <ContentSection
          heading5="Kontakt"
          heading2="Spojte se s námi!"
          paragraphs={[
            "Máte zájem o nějakou z našich otevřených pozic? Kontaktujte nás ještě dnes!",
            "hr@nelsoten.com",
            "+420 728 894 369",
            "Skořepka 1058/8, 110 00 Praha Staré Město"
          ]}
          className="content-section"
        />
        <div>
          <div className="form-section">
            <form className="career-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="firstName">Jméno</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formState.firstName || ''}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Příjmení</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formState.lastName || ''}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formState.email || ''}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Telefon</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  pattern="^(\+?[0-9]{1,3} )?[0-9]{3} [0-9]{3} [0-9]{3}$"
                  value={formState.phone || ''}
                  onChange={handlePhoneChange} // Použití vlastní funkce pro formátování čísla
                  placeholder='420 123 456 789'
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Zpráva</label>
                <textarea
                  id="message"
                  name="message"
                  value={formState.message || ''}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <div className="form-group-checkbox">
                <input
                  type="checkbox"
                  id="consent"
                  name="consent"
                  checked={isConsentChecked || false}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="consent">Souhlasím se zpracováním osobních údajů</label>
              </div>
              <button type="submit">Odeslat</button>
            </form>
            {notification && (
              <CustomNotification
                message={notification.message}
                type={notification.type}
                show={notification.show}
              />
            )}
          </div>
        </div>
      </section>

      <section>
        <Partners />
      </section>

      <section>
        <Newsletter />
      </section>
    </div>
  );
}

export default Carrier;
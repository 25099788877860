import { useState, useEffect } from 'react';

const useFormHandler = (formType, initialState = {}) => {
    const [formState, setFormState] = useState(initialState);
    const [notification, setNotification] = useState({ message: '', type: '', show: false });

    const formatPhoneNumber = (value) => {
        const cleaned = value.replace(/\D/g, ''); // Odstraní vše kromě čísel
        const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,3})(\d{0,3})$/);
    
        if (match) {
            return [match[1], match[2], match[3], match[4]]
                .filter(Boolean)
                .join(' ')
                .trim();
        }
        return value;
    };
    
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
    
        setFormState({
            ...formState,
            [name]: name === 'phone' ? formatPhoneNumber(value) : (type === 'checkbox' ? checked : value),
        });
    };

    const validateForm = () => {
        let formErrors = [];

        for (let key in formState) {
            if (formState.hasOwnProperty(key)) {
                if (key === 'agreement') {
                    if (formState[key] !== true) {
                        formErrors.push('Musíte souhlasit se zpracováním osobních údajů');
                    }
                } else {
                    if (typeof formState[key] !== 'string' || formState[key].trim() === '') {
                        formErrors.push(`${key.charAt(0).toUpperCase() + key.slice(1)} je povinné pole.`);
                    }
                }
            }
        }

        if (formErrors.length > 0) {
            setNotification({
                message: formErrors.join(', '),
                type: 'error',
                show: true,
            });
            return false;
        }

        return true;
    };

    const handleSubmit = async (e, callback) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const response = await fetch('/api/sendForm', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ formType, ...formState }),
                });

                if (response.ok) {
                    setNotification({ message: 'Formulář byl úspěšně odeslán!', type: 'success', show: true });
                    if (callback) callback();
                } else {
                    const responseData = await response.json();
                    setNotification({ message: responseData.message || 'Při odesílání formuláře došlo k chybě.', type: 'error', show: true });
                }
            } catch (error) {
                console.error('Error:', error);
                setNotification({ message: 'Při odesílání formuláře došlo k chybě.', type: 'error', show: true });
            }
        }
        
        setTimeout(() => {
            setNotification({ ...notification, show: false });
        }, 3000);
    };

    useEffect(() => {
        if (notification.show) {
            const timer = setTimeout(() => {
                setNotification({ ...notification, show: false });
            }, 3000);

            return () => clearTimeout(timer); // Vyčistí časovač, když se komponenta odpojí nebo je notifikace aktualizována
        }
    }, [notification]);

    const resetForm = () => {
        setFormState(initialState);
    };

    return {
        formState,
        handleChange,
        handleSubmit,
        notification,
        setNotification,
        resetForm,
    };
};

export default useFormHandler;
import React, { useEffect } from 'react'
import './Homepage.css'
import ContentSection from '../components/dynamic/ContentSection'
import Services from '../components/dynamic/Services'
import PartnersComponent from '../components/static/PartnersComponent'
import Steps from '../components/static/Steps'
import Testimonial from '../components/static/Testimonial'
import ContactUs from '../components/static/ContactUs'
import Newsletter from '../components/static/Newsletter'
import consultingImage from '../assets/img/strategic-consulting.png';
import educationImage from '../assets/img/graduate.png';
import analysisImage from '../assets/img/analysis.png';

import bg1 from '../assets/img/nelsoten-landing.jpg'
import bg2 from '../assets/img/min/nelsoten-landing-min.jpg'
import { useBackgroundImageLoader } from '../hooks/useImageLoader'; 

const Homepage = () => {
    const servicesData = [
        {
          icon: consultingImage,
          title: 'Konzultační a poradenské služby',
          description: 'Naše konzultační a poradenské služby Vám poskytnou širokou podporu a osobní poradenství, které Vám umožní dělat informovaná rozhodnutí na digitálních trzích.'
        },
        {
          icon: educationImage,
          title: 'Vzdělávací programy a semináře',
          description: 'Zapojte se do našich vzdělávacích programů a workshopů, abyste prohloubili své znalosti a dovednosti v oblasti tokenizovaných aktiv a kryptoměn. Získejte nástroje, které Vám pomohou dosáhnout úspěchu.'
        },
        {
          icon: analysisImage,
          title: 'Analýza trhu a identifikace příležitostí',
          description: 'Specializujeme se na důkladnou analýzu trhu, identifikaci klíčových trendů a využití příležitostí na neustále se vyvíjejících digitálních trzích.'
        }
      ];
      const { backgroundImage, loading, loaded } = useBackgroundImageLoader(bg2, bg1);

  return <div className='homepage-container'>
        <div
        className={`homepage-landing ${loading ? 'loading' : 'loaded'} ${loaded ? 'image-loaded' : ''}`}
        style={{ backgroundImage }}
      >
            <div className='homepage-landing-content'>
                <h1>Objevte investiční možnosti v digitálních aktivech a kryptoměnách</h1>
                <h3>Přinášíme Vám analýzy a identifikaci investičních příležitostí na dynamicky rostoucích trzích digitálních aktiv a kryptoměn. S našimi rozsáhlými znalostmi a individuálním přístupem pomáháme klientům dosáhnout jejich finančních cílů na těchto inovativních trzích.</h3>
            </div>
        </div>
    <section>
    <ContentSection 
        heading5="Vaše brána k úspěchu na digitálních trzích"
        heading2="Odemkněte nové perspektivy finančních trhů"
        paragraphs={[
          "Specializujeme se na poskytování poradenských služeb, které jsou pečlivě přizpůsobeny unikátním potřebám našich klientů v neustále se vyvíjejícím světě digitálních aktiv. Díky našim odborným analýzám tržních trendů a cílené identifikaci investičních příležitostí Vám nabízíme personalizovaná řešení, která Vás podpoří při orientaci na trzích s tokenizovanými aktivy a kryptoměnami.",
        ]}
        className="center-p"
      />
    </section>

    <section>
        <Services services={servicesData} />
    </section>

    <section>
        <PartnersComponent />
    </section>

    <section className='how-to'>
    <ContentSection 
        heading5="Jak začít?"
        heading2="Začněte s námi svou cestu na digitálních trzích. Zaměřte se na vzdělání a minimalizujte rizika ve svém obchodování."
        className="how-to-content"
      />
        <Steps />
    </section>

    <section>
      <ContactUs />
    </section>

    <section>
      <Testimonial />
    </section>

    <section>
      <Newsletter />
    </section>
    </div>
}

export default Homepage
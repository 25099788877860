import React from 'react';
import './Newsletter.css';
import bg1 from '../../assets/img/nelsoten-newsletter.jpg';
import bg2 from '../../assets/img/min/nelsoten-newsletter-min.jpg';
import { useBackgroundImageLoader } from '../../hooks/useImageLoader'; 
import useFormHandler from '../../hooks/useFormHandler';
import CustomNotification from '../dynamic/CustomNotification'; 

const Newsletter = () => {
  const initialState = {
    email: '',
  };
  
  const { formState, handleChange, handleSubmit, notification } = useFormHandler('newsletter', initialState); // Předání initialState
  const { backgroundImage, loading, loaded } = useBackgroundImageLoader(bg2, bg1);

  return (
    <div className={`newsletter-container ${loading ? 'loading' : 'loaded'}`} style={{ backgroundImage }}>
      <div className="newsletter-container_text">
        <h2>Zůstaňte v obraze s naším newsletterem</h2>
        <p>Získejte nejnovější informace o tokenizovaných aktivech a kryptoměnách přímo do Vaší e-mailové schránky.</p>
      </div>
      <div>
        <div className="newsletter-container_form">
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              placeholder="Zadejte svůj email"
              value={formState.email || ''} 
              onChange={handleChange}
            />
            <input type="submit" value="Odebírat" />
          </form>
        </div>
        {notification && <CustomNotification message={notification.message} type={notification.type} show={notification.show} />}
      </div>
    </div>
  );
};

export default Newsletter;
import React from 'react';
import './Services.css'

const ServicesSection = ({ services }) => {
  return (
    <div className="services-container">
      {services.map((service, index) => (
        <div key={index} className="service">
          <img src={service.icon} alt={service.title} />
          <h3>{service.title}</h3>
          <p>{service.description}</p>
        </div>
      ))}
    </div>
  );
};

export default ServicesSection;
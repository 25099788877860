// src/pages/NotFound.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-text">
        <h2>404</h2>
        <p>Ajaj! Tato stránka neexistuje.</p>
        <NavLink to="/" className="back-home">Zpět na domovskou stránku</NavLink>
      </div>
    </div>
  );
};

export default NotFound;
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import SharedLayout from './components/SharedLayout';
import Homepage from './pages/Homepage';
import AboutUs from './pages/AboutUs';
import NotFound from './pages/NotFound';
import Consultancy from './pages/Consultancy';
import Education from './pages/Education';
import Partners from './pages/Partners';
import Carrier from './pages/Carrier';
import Contact from './pages/Contact';
import ScrollToTop from './components/static/ScrollToTop';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<SharedLayout />}>
          <Route index element={<Homepage />} />
          <Route path='o-nas' element={<AboutUs />} />
          <Route path='poradenstvi' element={<Consultancy />} />
          <Route path='edukace' element={<Education />} />
          <Route path='nasi-partneri' element={<Partners />} />
          <Route path='kariera' element={<Carrier />} />
          <Route path='kontakt' element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
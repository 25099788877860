import React from 'react'
import AlverdoLogo from '../../assets/img/partners/Alverdo.svg'
import BinanceLogo from '../../assets/img/partners/Binance.svg'
import BitFinexLogo from '../../assets/img/partners/bitfinex.svg'
import CoinbaseLogo from '../../assets/img/partners/coinbase.svg'
import MexcLogo from '../../assets/img/partners/mexc.svg'

import './PartnersComponent.css'


const Partners = () => {
  return <div className='partners-container'>
        <h3>Výjimečné výsledky díky spolupráci s partnery po celém světě</h3>
        <div className="partners-logos-container">
            <img src={AlverdoLogo} alt="" />
            <img src={BinanceLogo} alt="" />
            <img src={BitFinexLogo} alt="" />
            <img src={CoinbaseLogo} alt="" />
            <img src={MexcLogo} alt="" />
        </div>
    </div>
}

export default Partners
import React from 'react'
import ContentSection from '../dynamic/ContentSection'
import Binance from "../../assets/img/partners/Binance-big.svg"
import Alverdo from "../../assets/img/partners/Alverdo-big.svg"
import Coinbase from "../../assets/img/partners/Coinbase-big.svg"
import './Partners.css'

const Partners = () => {
  return <div className="partners-component">
    <ContentSection 
        heading2="Naši partneři"
        className="centered-style"
      />
      <section className='partners-container'>
        <div className="partner-container">
            <img src={Binance} alt="Logo partnera" />
            <div className="partner-info">
            <div className="partner-desc">
                <h4>Binance</h4>
                <p>Krypto leader • České zastoupení</p>
                <p>Naše partnerství se společností Binance nám umožňuje poskytovat špičkové služby a inovativní řešení v oblasti kryptoměn. Díky jejich technologickým nástrojům a globálnímu dosahu můžeme našim klientům nabídnout jedinečné investiční příležitosti na trhu kryptoměn.</p>
            </div>
            <a href="https://www.binance.com/cs" target='_blank'>Vyzkoušet</a>
            </div>
        </div>

        <div className="partner-container">
            <img src={Alverdo} alt="Logo partnera" />
            <div className="partner-info">
            <div className="partner-desc">
                <h4>Alverdo</h4>
                <p>Tokenizované aktiva • České zastoupení</p>
                <p>Naše spolupráce se společností Alverdo nám umožňuje poskytovat pokročilá řešení v oblasti tokenizovaných aktiv. Alverdo se specializuje na digitalizaci reálných aktiv, což nám umožňuje nabídnout našim klientům bezpečný a efektivní přístup k investicím do různých typů tokenizovaných aktiv.</p>
            </div>
            <a href="https://alverdo.com/cs" target='_blank'>Vyzkoušet</a>
            </div>
        </div>

        <div className="partner-container">
            <img src={Coinbase} alt="Logo partnera" />
            <div className="partner-info">
            <div className="partner-desc">
                <h4>Coinbase</h4>
                <p>Krypto • Anglické zastoupení</p>
                <p>Díky partnerství se společností Coinbase můžeme našim klientům poskytovat špičkové služby a inovativní řešení v oblasti kryptoměn. Coinbase je známá svou bezpečností a jednoduchostí použití, což z ní činí ideální platformu pro investory, kteří chtějí vstoupit na trh kryptoměn.</p>
            </div>
            <a href="https://www.coinbase.com/" target='_blank'>Vyzkoušet</a>
            </div>
        </div>
      </section>
  </div>
}

export default Partners
import React from 'react'
import PartnersComponent from '../components/static/PartnersComponent'
import ContentSection from '../components/dynamic/ContentSection'
import FeatureHighlight from '../components/dynamic/FeatureHighlight'
import FAQ from '../components/static/FAQ'
import Newsletter from '../components/static/Newsletter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faLightbulb, faHandshake } from '@fortawesome/free-solid-svg-icons';
import bg1 from '../assets/img/nelsoten-bg5.jpg'
import bg2 from '../assets/img/min/nelsoten-bg5-min.jpg'
import { useBackgroundImageLoader } from '../hooks/useImageLoader'; 
import './AboutUs.css'

const AboutUs = () => {

    const sectionContent = {
        features: [
          {
            icon: faFileAlt,
            title: "Řešení na míru pro Váš finanční úspěch",
            description: "Naše služby přizpůsobujeme Vašim specifickým potřebám a cílům a zajišťujeme Vám individuální podporu po celou dobu Vaší cesty."
          },
          {
            icon: faHandshake,
            title: "Odborné vedení při každém kroku",
            description: "Náš tým zkušených odborníků Vám poskytne znalosti a poznatky, které potřebujete k informovanému rozhodování."
          },
          {
            icon: faLightbulb,
            title: "Získejte náskok díky našim znalostem",
            description: "Informujeme Vás o nejnovějších trendech a vývoji na digitálních trzích, čímž Vám poskytujeme konkurenční výhodu."
          }
        ]
      };

    const { backgroundImage, loading, loaded } = useBackgroundImageLoader(bg2, bg1);
  return (
  <div className='homepage-container'>
    <div
        className={`homepage-landing smaller-landing about-us ${loading ? 'loading' : 'loaded'} ${loaded ? 'image-loaded' : ''}`}
        style={{ backgroundImage }}
      >
        <div className='homepage-landing-content'>
            <h1>Poznejte Nelsoten</h1>
            <h3>Jsme Vaším důvěryhodným průvodcem v dynamickém světě digitálních aktiv a kryptoměn. Naše zkušenosti a odborné znalosti nám umožňují nabízet služby, které Vám pomohou dosáhnout Vašich finančních cílů s jistotou a přehledem.</h3>
        </div>
    </div>
    <section>
        <ContentSection
            heading5="Silnější pozice pro všechny"
            heading2="Transformujeme digitální trhy prostřednictvím špičkového odborného poradenství."
            paragraphs={[
              "",
              "Na dynamických a neustále se měnících finančních trzích je klíčové důkladně analyzovat příležitosti a strategicky je využít. To je základním předpokladem pro dosažení úspěchu a maximalizaci výnosů z Vašich investic.",
            ]}
            className="centered-style"
        />
    </section>
    <section>
        <FeatureHighlight 
            features={sectionContent.features}
        />
    </section>
    <section>
        <PartnersComponent />
    </section>
    <section>
        <FAQ />
    </section>
    <section>
      <Newsletter />
    </section>
</div>
  )
}

export default AboutUs
import { useState, useEffect } from 'react';

export const useBackgroundImageLoader = (placeholder, src) => {
  const [backgroundImage, setBackgroundImage] = useState(`url(${placeholder})`);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setBackgroundImage(`url(${src})`);
      setLoading(false);

      // Nastavíme krátké zpoždění, aby se změna opacity aplikovala plynule
      setTimeout(() => {
        setLoaded(true);
      }, 2000);
    };
  }, [src]);

  return { backgroundImage, loading, loaded };
};
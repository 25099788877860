import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './ImageTextSection.css'; // Import stylů
import LazyImage from './LazyImage'; // Import LazyImage komponenty
import Modal from './ModalForm'; // Import modální komponenty
import useFormHandler from '../../hooks/useFormHandler';

const ImageTextSection = ({ placeholderImage, image, title, subtitle, desc, features = [], buttonTextLeft, buttonTextRight, imageLeft, buttonHref }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    agreement: false,
  };

  const { formState, handleChange, handleSubmit, notification, resetForm } = useFormHandler('schuzka', initialState);

  const openModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
    resetForm();
  };

  return (
    <div className={`image-text-section ${imageLeft ? 'image-left' : 'image-right'}`}>
      <div className="image-container">
        <LazyImage src={image} placeholderSrc={placeholderImage} alt="Illustrative content" />
      </div>
      <div className="text-container">
        <h3>{subtitle}</h3>
        <h2>{title}</h2>
        <p>{desc}</p>

        {features.length > 0 && (
          <div className="features">
            {features.map((feature, index) => (
              <div key={index} className="feature">
                <div className="icon">{feature.icon}</div>
                <div className="text">
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="buttons">
          <NavLink to={buttonHref} className="btn btn-primary" style={{textDecoration: 'none'}}>{buttonTextLeft}</NavLink>
          <button className="btn btn-secondary" onClick={openModal}>{buttonTextRight}</button>
        </div>
      </div>

      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        onSubmit={(e) => handleSubmit(e, closeModal)}
        formState={formState}
        handleChange={handleChange}
        notification={notification} // Přidání notifikací do modalu
      />
    </div>
  );
};

export default ImageTextSection;
import React, { useState, useEffect } from 'react';

const LazyImage = ({ placeholderSrc, src, alt, ...props }) => {
  const [imageSrc, setImageSrc] = useState(placeholderSrc || src);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImageSrc(src);
      setImageLoaded(true);
    };
  }, [src]);

  return (
    <img
      src={imageSrc}
      alt={alt || ''}
      style={{
        filter: !imageLoaded ? 'blur(3px)' : 'none',
        transition: 'filter 0.3s ease-out',
      }}
      {...props}
    />
  );
};

export default LazyImage;
import React, { useEffect, useState, useRef } from 'react';
import { Element } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faBook, faCogs, faChartLine } from '@fortawesome/free-solid-svg-icons';
import './Steps.css';

const Steps = () => {
  const [progressHeight, setProgressHeight] = useState(0);
  const stepsContainerRef = useRef(null);

  useEffect(() => {
    const updateProgress = () => {
      if (!stepsContainerRef.current) return;

      const viewportHeight = window.innerHeight;
      const containerTop = stepsContainerRef.current.getBoundingClientRect().top + window.scrollY;
      const containerHeight = stepsContainerRef.current.offsetHeight;
      const scrollStart = containerTop - viewportHeight * 0.5;
      const scrollEnd = containerTop + containerHeight - viewportHeight * 0.5;

      const scrolled = window.scrollY;
      let progress = 0;
      if (scrolled >= scrollStart && scrolled <= scrollEnd) {
        progress = ((scrolled - scrollStart) / (scrollEnd - scrollStart)) * 100;
      } else if (scrolled > scrollEnd) {
        progress = 100;
      }
      setProgressHeight(Math.min(Math.max(progress, 0), 100));
    };

    window.addEventListener('scroll', updateProgress);
    updateProgress();

    return () => {
      window.removeEventListener('scroll', updateProgress);
    };
  }, []);

  const getIconColor = (stepThreshold) => {
    return progressHeight >= stepThreshold ? '#2e92a2' : 'gray';
  };

  return (
    <div className="process-steps-container" ref={stepsContainerRef}>
      <div className="progress-bar-wrapper">
        <div className="progress-bar" style={{ height: `${progressHeight}%` }} />
      </div>
      <div className="steps">
        <Element name="meeting" className="element">
          <FontAwesomeIcon icon={faCoffee} size="3x" className="icon" style={{ color: getIconColor(0.2) }} />
          <div className="element-header">
            <h3>Využijte bezplatné schůzky</h3>
            <p>Domluvte si schůzku s našimi odborníky a získejte bezplatné poradenství.</p>
          </div>
        </Element>

        <Element name="analysis" className="element">
          <FontAwesomeIcon icon={faBook} size="3x" className="icon" style={{ color: getIconColor(15) }} />
          <div className="element-header">
            <h3>Analýza a strategie</h3>
            <p>Detailně prozkoumáme Vaše potřeby a vypracujeme strategický plán.</p>
          </div>
        </Element>

        <Element name="execution" className="element">
          <FontAwesomeIcon icon={faCogs} size="3x" className="icon" style={{ color: getIconColor(47) }} />
          <div className="element-header">
            <h3>Realizace a podpora</h3>
            <p>Pomůžeme Vám s uskutečněním strategií a budeme Vás podporovat na každém kroku.</p>
          </div>
        </Element>

        <Element name="evaluation" className="element">
          <FontAwesomeIcon icon={faChartLine} size="3x" className="icon" style={{ color: getIconColor(81) }} />
          <div className="element-header">
            <h3>Vyhodnocení a optimalizace</h3>
            <p>Pravidelně hodnotíme a upravujeme postupy, abychom pro Vás zajistili ty nejlepší výsledky.</p>
          </div>
        </Element>
      </div>
    </div>
  );
};

export default Steps;
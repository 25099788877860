import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom'; // Import NavLink pro navigaci
import './FeatureHighlight.css';
import Modal from './ModalForm'; // Import modální komponenty
import useFormHandler from '../../hooks/useFormHandler'; // Import hooku

const FeatureHighlight = ({ headline, subheadline, features }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    agreement: false,
  };

  const { formState, handleChange, handleSubmit, notification, resetForm } = useFormHandler('schuzka', initialState);

  const openModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
    resetForm();
  };

  return (
    <div className="feature-highlight">
      <h1>{headline}</h1>
      <p>{subheadline}</p>
      <div className="features-container">
        {features.map((feature, index) => (
          <div key={index} className="feature">
            <FontAwesomeIcon icon={feature.icon} size="6x" className="icon" />
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
      <div className="buttons-container">
        <NavLink to="/kontakt" className="btn btn-primary" style={{textDecoration: 'none'}}>Chci vědět více</NavLink>
        <button className="btn btn-secondary" onClick={openModal}>Sjednat schůzku</button>
      </div>

      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        onSubmit={(e) => handleSubmit(e, closeModal)}
        formState={formState}
        handleChange={handleChange}
        notification={notification}
      />
    </div>
  );
};

export default FeatureHighlight;
import React from 'react';
import './ModalForm.css';
import CustomNotification from '../dynamic/CustomNotification'; // Import notifikací

const ModalForm = ({ isOpen, onClose, onSubmit, formState, handleChange, notification }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <button className="close-modal" onClick={onClose}>
          &times;
        </button>
        <h2>Sjednat schůzku</h2>
        <form className="modal-form" onSubmit={onSubmit}>
          <div className="form-group">
            <label>Jméno</label>
            <input
              type="text"
              name="firstName"
              value={formState.firstName || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Příjmení</label>
            <input
              type="text"
              name="lastName"
              value={formState.lastName || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formState.email || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Telefon</label>
            <input
              type="tel"
              name="phone"
              placeholder="420 123 456 789"
              value={formState.phone || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group-checkbox">
            <input
              type="checkbox"
              name="agreement"
              id="agreement"
              checked={formState.agreement || false}
              onChange={handleChange}
              required
            />
            <label htmlFor="agreement" style={{ marginLeft: '5px', cursor: 'pointer' }}>
              Souhlasím se zpracováním osobních údajů a obchodními podmínkami
            </label>
          </div>
          <button type="submit">Odeslat</button>
        </form>
      </div>
      {notification && notification.show && (
        <CustomNotification message={notification.message} type={notification.type} show={notification.show} />
      )}
    </div>
  );
};

export default ModalForm;
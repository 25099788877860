import React from 'react';
import './Footer.css'; // Vytvořte tento soubor pro stylování footera
import useFormHandler from '../../hooks/useFormHandler';
import CustomNotification from '../dynamic/CustomNotification';
import logo from '../../assets/img/Nelsoten.svg'

const Footer = () => {
  const initialState = {
    email: '',
  };

  const { formState, handleChange, handleSubmit, notification } = useFormHandler('newsletter', initialState);

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <img src={logo} alt="NELSOTEN" />
        </div>
        <div className="footer-info">
          <h3>Informace</h3>
          <ul>
            <li><a href="/o-nas">O nás</a></li>
            <li><a href="/poradenstvi">Poradenství</a></li>
            <li><a href="/edukace">Edukace</a></li>
            <li><a href="/partneři">Partneři</a></li>
            <li><a href="/kontakt">Kontakt</a></li>
          </ul>
        </div>
        <div className="footer-subscribe">
          <h3>Přihlaste se k odběru</h3>
          <p>Připojte se k naší komunitě a buďte informováni o nejnovějších událostech na finančních trzích.</p>
          <form className="subscribe-form" onSubmit={handleSubmit}>
            <input 
              type="email" 
              name="email"
              placeholder="Zadejte svůj email" 
              value={formState.email || ''}
              onChange={handleChange}
              required 
            />
            <input type="submit" value={"Odeslat"} />
          </form>
          {notification && notification.show && (
            <CustomNotification 
              message={notification.message} 
              type={notification.type} 
              show={notification.show} 
            />
          )}
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 NELSOTEN. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faStar } from '@fortawesome/free-solid-svg-icons';
import testimonialsData from '../../assets/data/reviews.json';
import './Testimonial.css';

const Testimonial = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        setTestimonials(testimonialsData);  // Nahrání dat při prvním načtení
    }, []);

    const handlePrev = () => {
        // Změní index na předchozí testimonial nebo na poslední, pokud je již na prvním
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : testimonials.length - 1));
    };

    const handleNext = () => {
        // Změní index na následující testimonial nebo na první, pokud je na konci
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    return (
        <div className="testimonial-container">
            {testimonials.length > 0 && (
                <>
                    <div className="navigation">
                        <button onClick={handlePrev}><FontAwesomeIcon icon={faChevronLeft} /></button>
                        <button onClick={handleNext}><FontAwesomeIcon icon={faChevronRight} /></button>
                    </div>
                    <div className="content">
                        <p>{testimonials[currentIndex].review}</p>
                        <div className="rating">
                            {[...Array(testimonials[currentIndex].rating)].map((e, i) => (
                                <FontAwesomeIcon key={i} icon={faStar} />
                            ))}
                        </div>
                        <div className="author-info">
                            <span>{testimonials[currentIndex].name}, {testimonials[currentIndex].age}</span>
                            <span>{testimonials[currentIndex].occupation}</span>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Testimonial;
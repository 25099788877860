import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './FAQ.css'; // Import CSS stylů

const FAQ = () => {
  const [open, setOpen] = useState(null);

  const toggle = (index) => {
    setOpen(open === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2>FAQs</h2>
      <p>Najděte odpovědi na často kladené otázky týkající se tokenizovaných aktiv a poradenského přístupu společnosti Nelsoten.</p>

      <div className="faq-item" onClick={() => toggle(1)}>
        <div className="faq-question">
          <h3>Co jsou tokenizovaná aktiva?</h3>
          <FontAwesomeIcon icon={open === 1 ? faChevronUp : faChevronDown} />
        </div>
        {open === 1 && (
          <p>
            Tokenizovaná aktiva představují digitální tokeny, které jsou navázány na hodnotu reálných aktiv, jako jsou například kryptoměny typu Bitcoin. Hodnota těchto tokenů se mění v souladu s hodnotou podkladových aktiv, což přináší podobné investiční příležitosti jako tradiční finanční nástroje.
          </p>
        )}
        <hr />
      </div>

      <div className="faq-item" onClick={() => toggle(2)}>
        <div className="faq-question">
          <h3>Jak může Nelsoten pomoci?</h3>
          <FontAwesomeIcon icon={open === 2 ? faChevronUp : faChevronDown} />
        </div>
        {open === 2 && (
          <p>
            Nelsoten poskytuje komplexní poradenské služby, které Vám pomohou lépe se orientovat ve světě tokenizovaných aktiv. Naši odborníci Vám poskytnou detailní analýzu trhu, pomohou identifikovat investiční příležitosti a nabídnou individuální podporu pro Vaše finanční cíle.
          </p>
        )}
        <hr />
      </div>

      <div className="faq-item" onClick={() => toggle(3)}>
        <div className="faq-question">
          <h3>Co je technologie blockchain?</h3>
          <FontAwesomeIcon icon={open === 3 ? faChevronUp : faChevronDown} />
        </div>
        {open === 3 && (
          <p>
            Blockchain je decentralizovaná digitální účetní kniha, která umožňuje bezpečné a transparentní zaznamenávání transakcí na více počítačích současně. Je to klíčová technologie, která podporuje kryptoměny a tokenizovaná aktiva, zajišťující vysokou úroveň bezpečnosti.
          </p>
        )}
        <hr />
      </div>

      <div className="faq-item" onClick={() => toggle(4)}>
        <div className="faq-question">
          <h3>Jsou tokenizovaná aktiva bezpečná?</h3>
          <FontAwesomeIcon icon={open === 4 ? faChevronUp : faChevronDown} />
        </div>
        {open === 4 && (
          <p>
            Tokenizovaná aktiva využívají technologie blockchain, která poskytuje vyšší úroveň bezpečnosti ve srovnání s tradičními finančními nástroji. Pro maximální bezpečnost je důležité využívat ověřené platformy a dodržovat osvědčené postupy při jejich správě a obchodování.
          </p>
        )}
        <hr />
      </div>

      <div className="faq-item" onClick={() => toggle(5)}>
        <div className="faq-question">
          <h3>Jak mohu začít?</h3>
          <FontAwesomeIcon icon={open === 5 ? faChevronUp : faChevronDown} />
        </div>
        {open === 5 && (
          <p>
            Chcete-li začít s tokenizovanými aktivy nebo využít poradenské služby Nelsoten, stačí nás kontaktovat. Naši experti Vám poskytnou individuální konzultace a podporu, abyste mohli bezpečně a efektivně investovat.
          </p>
        )}
        <hr />
      </div>

      <div className="faq-item" onClick={() => toggle(6)}>
        <div className="faq-question">
          <h3>Máte další otázky?</h3>
          <FontAwesomeIcon icon={open === 6 ? faChevronUp : faChevronDown} />
        </div>
        {open === 6 && (
          <p>
            Pokud máte další dotazy nebo potřebujete více informací, neváhejte nás kontaktovat. Jsme zde, abychom Vám pomohli s Vašimi investičními potřebami.
          </p>
        )}
        <hr />
      </div>

      <button className="contact-button">Kontaktujte nás</button>
    </div>
  );
};

export default FAQ;
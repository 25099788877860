import React from 'react'
import ImageTextSection from '../components/dynamic/ImageTextSection'
import Steps from '../components/static/Steps';
import img1 from '../assets/img/nelsoten-education1.jpg'
import piimg1 from '../assets/img/min/nelsoten-education1-min.jpg'
import Testimonial from '../components/static/Testimonial';
import Newsletter from '../components/static/Newsletter';
import ContactUs from '../components/static/ContactUs';
import ContentSection from '../components/dynamic/ContentSection';
import bg1 from '../assets/img/nelsoten-bg6.jpg'
import bg2 from '../assets/img/min/nelsoten-bg6-min.jpg'
import { useBackgroundImageLoader } from '../hooks/useImageLoader'; 

const Education = () => {
    const sectionOne = {
        placeholderImage: piimg1,
        image: img1,
        title: 'Prohlubte své znalosti s našimi komplexními vzdělávacími kurzy',
        desc: 'Pochopení tržních trendů, důkladný průzkum a dobrá informovanost o ekonomickém vývoji jsou klíčové pro efektivní finanční rozhodování. Naše vzdělávací kurzy Vám poskytnou hluboké poznatky a osvědčené strategie, které Vám umožní sebevědomě se pohybovat na dynamických finančních trzích.',
        subtitle: 'Strategie',
        buttonTextLeft: 'Chci vědět více',
        buttonTextRight: 'Sjednat schůzku',
        buttonHref: '/kontakt',
        imageLeft: true,
      };

      const { backgroundImage, loading, loaded } = useBackgroundImageLoader(bg2, bg1);

  return <div className='homepage-container education'>
    <div
       className={`homepage-landing smaller-landing ${loading ? 'loading' : 'loaded'} ${loaded ? 'image-loaded' : ''}`}
        style={{ backgroundImage }}
      >
      <div className='homepage-landing-content'>
          <h1 >Vzdělávání v oblasti digitálních aktiv</h1>
          <h3>Ať už jste nováček nebo zkušený investor, naše vzdělávací programy Vám pomohou rozšířit Vaše znalosti o digitálních trzích. Naučíme Vás, jak bezpečně investovat, chápat tržní trendy a činit informovaná rozhodnutí.</h3>
      </div>
  </div>
    <section>
        <ImageTextSection {...sectionOne}/>
    </section>
    
    <section className='how-to' style={{marginBottom: 0}}>
    <ContentSection 
        heading5="Jak začít?"
        heading2="Začněte s námi svou cestu na digitálních trzích. Zaměřte se na vzdělání a minimalizujte rizika ve svém obchodování."
        paragraphs={[
          '',
        ]}
        className="how-to-content"
      />
        <Steps />
    </section>
    <section style={{marginTop: 0}}>
        <Testimonial />
    </section>
    <section>
        <ContactUs />
    </section>
    <section>
        <Newsletter />
    </section>
</div>
}

export default Education
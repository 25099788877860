import React from 'react';
import './ContentSection.css';

const ContentSection = ({ heading5, heading2, paragraphs, className }) => {
  return (
    <div className="content-section-div">
    <div className={className}>
      <h5>{heading5}</h5>
      <h2>{heading2}</h2>
      {paragraphs && paragraphs.length > 0 ? (
        paragraphs.map((paragraph, index) => (
          <p key={index} style={{ fontWeight: index === 0 ? 'bold' : 'normal' }}>
            {paragraph}
          </p>
        ))
      ) : (
        <p></p>
      )}
    </div>
    </div>
  );
};

export default ContentSection;
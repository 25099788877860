import React, { useState } from 'react';
import './Contact.css';
import bg1 from '../assets/img/nelsoten-landing.jpg';
import bg2 from '../assets/img/min/nelsoten-landing-min.jpg';
import Newsletter from '../components/static/Newsletter'
import { useBackgroundImageLoader } from '../hooks/useImageLoader'; 
import useFormHandler from '../hooks/useFormHandler';
import CustomNotification from '../components/dynamic/CustomNotification'; 

const Contact = () => {
  const { backgroundImage, loading, loaded } = useBackgroundImageLoader(bg2, bg1);
  const { formState, handleChange, handleSubmit, notification } = useFormHandler('kontakt');

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, ''); // Odstraní vše kromě čísel
    const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,3})(\d{0,3})$/);

    if (match) {
      return [match[1], match[2], match[3], match[4]]
        .filter(Boolean)
        .join(' ')
        .trim();
    }
    return value;
  };

  const handlePhoneChange = (e) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    handleChange({
      target: {
        name: e.target.name,
        value: formattedValue,
      },
    });
  };

  return (
    <div className='homepage-container consultancy'>
      <div
        className={`homepage-landing contact smaller-landing ${loading ? 'loading' : 'loaded'} ${loaded ? 'image-loaded' : ''}`}
        style={{ backgroundImage }}
      >
        <div className='homepage-landing-content'>
          <h1>Spojte se s námi</h1>
          <h3>Jsme tu, abychom Vám poskytli podporu a odborné poradenství na Vaší cestě za finančními cíli. Kontaktujte nás, ať už hledáte investiční rady, vzdělávání nebo chcete probrat Vaše finanční možnosti.</h3>
        </div>
      </div>
      <div className="contact-container">
        <section className="direct-contact">
          <h1>Kontaktujte Nelsoten</h1>
          <p>Ozvěte se nám telefonicky či emailem, nebo nás navštivte osobně.</p>
          <div className="contact-info">
            <div className="contact-item">
              <i className="icon-email"></i>
              <h4>Email</h4>
              <p>Pošlete nám e-mail</p>
              <a href="mailto:hello@nelsoten.cz">hello@nelsoten.cz</a>
            </div>
            <div className="contact-item">
              <i className="icon-phone"></i>
              <h4>Telefon</h4>
              <p>Zavolejte nám</p>
              <a href="tel:+420728894369">+420 728 894 369</a>
            </div>
            <div className="contact-item">
              <i className="icon-location"></i>
              <h4>Sídlo</h4>
              <p>Skořepka 1058/8, 110&nbsp;00&nbsp;Praha&nbsp;Staré Město</p>
            </div>
          </div>
        </section>
        
        <section className="contact-form-section">
          <h3>Kontaktní formulář</h3>
          <h1>Dejte nám vědět</h1>
          <p>Máte dotaz nebo potřebujete pomoc? Kontaktujte nás!</p>
          
          <div>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="firstName">Jméno</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formState.firstName || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Příjmení</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formState.lastName || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formState.email || ''}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Telefon</label>
                <input
                  type="tel"
                  id="phone"
                  placeholder='420 123 456 789'
                  name="phone"
                  pattern="^(\+?[0-9]{1,3} )?[0-9]{3} [0-9]{3} [0-9]{3}$"
                  value={formState.phone || ''}
                  onChange={handlePhoneChange} // Použití vlastní funkce pro formátování čísla
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Zpráva</label>
                <textarea
                  id="message"
                  name="message"
                  value={formState.message || ''}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <div className="form-group-checkbox">
                <input
                  type="checkbox"
                  id="agreement"
                  name="agreement"
                  checked={formState.agreement || false}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="agreement" style={{ textAlign: 'left', marginLeft: '5px' }}>
                  Souhlasím se zpracováním osobních údajů podle Zásad ochrany osobních údajů.
                </label>
              </div>
              <button type="submit">Odeslat</button>
            </form>
            {notification && (
              <CustomNotification
                message={notification.message}
                type={notification.type}
                show={notification.show}
              />
            )}
          </div>
        </section>
        <section>
          <Newsletter />
        </section>
      </div>
    </div>
  );
};

export default Contact;
